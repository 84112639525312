import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import areaService from "./areaService";

export const getAreas = createAsyncThunk(
  "area/get-areas",
  async (thunkAPI) => {
    try {
      return await areaService.getAreas();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getAArea = createAsyncThunk(
  "area/get-area",
  async (id, thunkAPI) => {
    try {
      return await areaService.getArea(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const createArea = createAsyncThunk(
  "area/create-area",
  async (areaData, thunkAPI) => {
    try {
      return await areaService.createArea(areaData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateAArea = createAsyncThunk(
  "area/update-area",
  async (area, thunkAPI) => {
    try {
      return await areaService.updateArea(area);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAArea = createAsyncThunk(
  "area/delete-area",
  async (id, thunkAPI) => {
    try {
      return await areaService.deleteArea(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  areas: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const areaSlice = createSlice({
  name: "areas",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAreas.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAreas.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.areas = action.payload;
      })
      .addCase(getAreas.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createArea.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createArea.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdArea = action.payload;
      })
      .addCase(createArea.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getAArea.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAArea.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.areaName = action.payload.title;
      })
      .addCase(getAArea.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateAArea.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAArea.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedArea = action.payload;
      })
      .addCase(updateAArea.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(deleteAArea.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAArea.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedArea = action.payload;
      })
      .addCase(deleteAArea.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
  },
});

export default areaSlice.reducer;

import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import typeService from "./typeService";

export const getTypes = createAsyncThunk(
  "type/get-types",
  async (thunkAPI) => {
    try {
      return await typeService.getTypes();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getAType = createAsyncThunk(
  "type/get-type",
  async (id, thunkAPI) => {
    try {
      return await typeService.getType(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const createType = createAsyncThunk(
  "type/create-type",
  async (typeData, thunkAPI) => {
    try {
      return await typeService.createType(typeData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateAType = createAsyncThunk(
  "type/update-type",
  async (type, thunkAPI) => {
    try {
      return await typeService.updateType(type);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAType = createAsyncThunk(
  "type/delete-type",
  async (id, thunkAPI) => {
    try {
      return await typeService.deleteType(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  types: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const typeSlice = createSlice({
  name: "types",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTypes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.types = action.payload;
      })
      .addCase(getTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createType = action.payload;
      })
      .addCase(createType.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getAType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.typeName = action.payload.title;
      })
      .addCase(getAType.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateAType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedType = action.payload;
      })
      .addCase(updateAType.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(deleteAType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedType = action.payload;
      })
      .addCase(deleteAType.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
  },
});

export default typeSlice.reducer;

import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";
const getViews = async () => {
  const response = await axios.get(`${base_url}view/`);

  return response.data;
};

const createView = async (view) => {
  const response = await axios.post(`${base_url}view/`, view, config);

  return response.data;
};
const updateView = async (view) => {
  const response = await axios.put(
    `${base_url}view/${view.id}`,
    { title: view.viewData.title },
    config
  );

  return response.data;
};
const getView = async (id) => {
  const response = await axios.get(`${base_url}view/${id}`, config);

  return response.data;
};

const deleteView = async (id) => {
  const response = await axios.delete(`${base_url}view/${id}`, config);

  return response.data;
};

const viewService = {
  getViews,
  createView,
  getView,
  updateView,
  deleteView,
};

export default viewService;

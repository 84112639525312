import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import viewService from "./viewService";

export const getViews = createAsyncThunk(
  "view/get-views",
  async (thunkAPI) => {
    try {
      return await viewService.getViews();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getAView = createAsyncThunk(
  "view/get-view",
  async (id, thunkAPI) => {
    try {
      return await viewService.getView(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const createView = createAsyncThunk(
  "view/create-view",
  async (viewData, thunkAPI) => {
    try {
      return await viewService.createView(viewData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateAView = createAsyncThunk(
  "view/update-view",
  async (view, thunkAPI) => {
    try {
      return await viewService.updateView(view);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAView = createAsyncThunk(
  "view/delete-view",
  async (id, thunkAPI) => {
    try {
      return await viewService.deleteView(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  views: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const viewSlice = createSlice({
  name: "views",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getViews.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getViews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.views = action.payload;
      })
      .addCase(getViews.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createView.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createView.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdView = action.payload;
      })
      .addCase(createView.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getAView.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAView.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.viewName = action.payload.title;
      })
      .addCase(getAView.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateAView.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAView.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedView = action.payload;
      })
      .addCase(updateAView.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(deleteAView.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAView.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedView = action.payload;
      })
      .addCase(deleteAView.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
  },
});

export default viewSlice.reducer;

import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";
const getTypes = async () => {
  const response = await axios.get(`${base_url}type/`);

  return response.data;
};

const createType = async (type) => {
  const response = await axios.post(`${base_url}type/`, type, config);

  return response.data;
};
const updateType = async (type) => {
  const response = await axios.put(
    `${base_url}type/${type.id}`,
    { title: type.typeData.title },
    config
  );

  return response.data;
};
const getType = async (id) => {
  const response = await axios.get(`${base_url}type/${id}`, config);

  return response.data;
};

const deleteType = async (id) => {
  const response = await axios.delete(`${base_url}type/${id}`, config);

  return response.data;
};

const typeService = {
  getTypes,
  createType,
  getType,
  updateType,
  deleteType,
};

export default typeService;

import { React, useEffect } from "react";
import CustomInput from "../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  createArea,
  getAArea,
  resetState,
  updateAArea,
} from "../features/area/areaSlice";

let schema = yup.object().shape({
  title: yup.string().required("Area Name is Required"),
});
const Addarea = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const getAreaId = location.pathname.split("/")[3];
  const newArea = useSelector((state) => state.area);
  const {
    isSuccess,
    isError,
    isLoading,
    createdArea,
    areaName,
    updatedArea,
  } = newArea;
  useEffect(() => {
    if (getAreaId !== undefined) {
      dispatch(getAArea(getAreaId));
    } else {
      dispatch(resetState());
    }
  }, [getAreaId]);

  useEffect(() => {
    if (isSuccess && createdArea) {
      toast.success("Area Added Successfullly!");
    }
    if (isSuccess && updatedArea) {
      toast.success("Area Updated Successfullly!");
      navigate("/admin/list-area");
    }

    if (isError) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, isError, isLoading]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: areaName || "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (getAreaId !== undefined) {
        const data = { id: getAreaId, areaData: values };
        dispatch(updateAArea(data));
        dispatch(resetState());
      } else {
        dispatch(createArea(values));
        formik.resetForm();
        setTimeout(() => {
          dispatch(resetState());
        }, 300);
      }
    },
  });

  return (
    <div>
      <h3 className="mb-4 title">
        {getAreaId !== undefined ? "Edit" : "Add"} Area
      </h3>
      <div>
        <form action="" onSubmit={formik.handleSubmit}>
          <CustomInput
            type="text"
            name="title"
            onChng={formik.handleChange("title")}
            onBlr={formik.handleBlur("title")}
            val={formik.values.title}
            label="Enter Area"
            id="area"
          />
          <div className="error">
            {formik.touched.title && formik.errors.title}
          </div>
          <button
            className="btn btn-success border-0 rounded-3 my-5"
            type="submit"
          >
            {getAreaId !== undefined ? "Edit" : "Add"} Area
          </button>
        </form>
      </div>
    </div>
  );
};

export default Addarea;

import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";
const getAreas = async () => {
  const response = await axios.get(`${base_url}area/`);

  return response.data;
};

const createArea = async (area) => {
  const response = await axios.post(`${base_url}area/`, area, config);

  return response.data;
};
const updateArea = async (area) => {
  const response = await axios.put(
    `${base_url}area/${area.id}`,
    { title: area.areaData.title },
    config
  );

  return response.data;
};
const getArea = async (id) => {
  const response = await axios.get(`${base_url}area/${id}`, config);

  return response.data;
};

const deleteArea = async (id) => {
  const response = await axios.delete(`${base_url}area/${id}`, config);

  return response.data;
};

const areaService = {
  getAreas,
  createArea,
  getArea,
  updateArea,
  deleteArea,
};

export default areaService;
